/* @media print { */
/* Prevent page breaks inside tables */
table {
  page-break-inside: avoid;
}

/* Prevent page break before the second table */
.second-sub-table {
  page-break-before: avoid;
  page-break-after: avoid;
  page-break-inside: avoid;
}

/* Optional: Ensure the entire container with both tables is printed together */
.tables-container {
  page-break-inside: avoid;
  page-break-after: avoid;
  display: flex; /* Use flexbox to align tables side by side */
  justify-content: space-between; /* Add space between the tables */
  gap: 20px; /* Adjust the gap as needed */
  width: 100%; /* Ensure the container takes the full width */
}

/* General table row handling */
tr {
  page-break-inside: avoid;
}

/* Ensure headers repeat on each page if content is long */
thead {
  display: table-header-group;
}

tfoot {
  display: table-footer-group;
}
/* } */
